.feed-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}

.feed-card {
    flex: 1 1 300px;
    max-width: 500px;
    margin: 10px;
}

.post-img {
    width: 100%;
  
    object-fit: contain;
}

.card-header-custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.header-left {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.card-footer-custom {
    display: flex;
    justify-content: space-between;
  
}

.share-buttons-container {
    display: flex;
    justify-content: space-between;
}
.post-video {
    width: 100%;
 
    object-fit: cover;
  }
  .comment-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .profile-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ddd;
    margin-right: 10px;
    margin-top: -15PX;
  }
  
  .profile-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .initials {
    font-size: 18px;
    font-weight: bold;
    color: #555;
    margin-top: 0;
  }

  
  .comment-section {
    max-height: 240px; /* Adjust height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }